<template>
  <v-slide-x-transition appear mode="out-in">
    <div v-if="user && user.id > 0">
      <setup-guide-view v-if="!isFirstSetupDone"></setup-guide-view>
      <account-subscription-expired-component @logout="logoutUser"
                                              v-else-if="user.accountSubscription && user.accountSubscription.expired"
                                              :account-subscription="user.accountSubscription"></account-subscription-expired-component>
      <div v-else>
        <tour-component></tour-component>
        <NavigationDrawerComponent @logout="logoutUser" :drawer.sync="drawer"></NavigationDrawerComponent>
        <v-app-bar
            app
            clipped-left
            color="primary"
            dark
            dense
            fixed
        >
          <v-app-bar-nav-icon @click.stop="drawer = !drawer">
            <v-icon>{{ drawer ? 'menu_open' : 'menu' }}</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title class="pl-3 pl-sm-6 pr-8 pt-1">
            <router-link :to="{name: 'home'}">
              <logo-component :height="logoSize" :width="logoSize" color="white"></logo-component>
            </router-link>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-layout align-center row style="max-width: 650px">
            <global-search-component></global-search-component>
          </v-layout>
          <v-fade-transition appear>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                                <span v-on="on">
                                    <v-menu offset-y transition="slide-y-transition">
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on"
                                                   class="ml-7 mr-3" icon small>
                                                <v-icon color="white">mdi-help-circle-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <support-menu-component :show-tour-btn="showTourBtn"
                                                                @startTour="startTour"></support-menu-component>
                                    </v-menu>
                                </span>
              </template>
              <span>Hjälp</span>
            </v-tooltip>
          </v-fade-transition>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                            <span
                                id="headwayChangelog"
                                v-on="on"></span>
            </template>
            <span>Nyheter och uppdateringar</span>
          </v-tooltip>
        </v-app-bar>
        <v-main>
          <v-container grid-list-sm>
            <v-slide-x-transition mode="out-in">
              <router-view :key="$route.fullPath"></router-view>
            </v-slide-x-transition>
          </v-container>
        </v-main>
        <v-progress-linear :active="appIsLoading" class="bottom-loader" indeterminate></v-progress-linear>
      </div>
      <v-snackbar
          v-model="snackbar.active"
          :color="snackbar.color"
          data-cy="globalSnackbar"
          :timeout="snackbar.timeout"
      >
        <p>
          <span v-if="snackbar.title" class="font-weight-bold">{{ snackbar.title }} <br></span>
          {{ snackbar.text }}
        </p>
        <template v-slot:action>
          <v-btn
              dark
              text
              @click="closeSnackbar"
          >
            Stäng
          </v-btn>
          <v-btn
              v-if="snackbar.actionClick"
              dark
              text
              @click="snackbarActionClick"
          >
            {{ snackbar.btnText }}
          </v-btn>
          <v-btn
              v-else-if="snackbar.url"
              :href="snackbar.url"
              dark
              text
          >
            {{ snackbar.btnText }}
          </v-btn>
        </template>
      </v-snackbar>
      <v-dialog v-model="dialog.active" max-width="550" persistent>
        <v-card>
          <v-card-title class="headline secondary--text">{{ dialog.title }}</v-card-title>
          <v-card-text>
            {{ dialog.text }}
            <v-alert v-if="dialog && dialog.alert" :color="dialog.alert.color" :type="dialog.alert.type" class="mt-6"
                     dense
                     outlined>
              {{ dialog.alert.text }}
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn data-cy="globalDialogCancelBtn" class="left" text @click="closeDialog">{{
                dialog.closeBtnText
              }}
            </v-btn>
            <v-btn data-cy="globalDialogActionBtn" color="primary" text @click="dialogActionClick">
              {{ dialog.actionBtnText }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- Uthyrd fancy loader while we check if user is logged in -->
    <uthyrd-loader-component v-else loadingText="Laddar in Uthyrd..."></uthyrd-loader-component>
  </v-slide-x-transition>
</template>

<script>
import LogoComponent from "./LogoComponent";
import api from "../../api/api";
import UthyrdLoaderComponent from "./UthyrdLoaderComponent";
import websocket from "../../websocket/websocket";
import SetupGuideView from "../../views/setupguide/SetupGuideView";
import TourComponent from "../tour/TourComponent";
import SupportMenuComponent from "../support/SupportMenuComponent";
import GlobalSearchComponent from "../search/GlobalSearchComponent";
import AccountSubscriptionExpiredComponent
  from "@/components/useraccount/accountsubscription/AccountSubscriptionExpiredComponent";
import NavigationDrawerComponent from "@/components/global/NavigationDrawerComponent.vue";
import accountSubscriptionTypes from "@/models/accountSubscriptionTypes";
import constants from "@/util/constants";

export default {
  name: 'AppComponent',
  components: {
    NavigationDrawerComponent,
    AccountSubscriptionExpiredComponent,
    GlobalSearchComponent,
    SupportMenuComponent, TourComponent, SetupGuideView, UthyrdLoaderComponent, LogoComponent
  },
  data: () => ({
    drawer: true,
    socket: null,
    currentTour: null,
  }),
  computed: {
    user() {
      return this.$store.state.user
    },
    isFirstSetupDone() // make sure user has a valid applicationEmailAddress
    {
      return this.user && this.user.applicationEmailAddress && this.user.applicationEmailAddress.length > 0 && this.user.applicationEmailAddress.indexOf("@") > 0
    },
    appIsLoading() {
      return this.$store.state.appIsLoading
    },
    snackbar() {
      return this.$store.state.snackbar
    },
    dialog() {
      return this.$store.state.dialog
    },
    currentRoute() {
      return this.$store.state.currentRoute
    },
    editTour() {
      return this.$store.state.editTour
    },
    showTourBtn() {
      return this.currentTour && this.currentTour.id && this.currentTour.id >= 0
    },
    logoSize() {
      return this.$vuetify.breakpoint.smAndDown ? "32px" : "38px"
    },
  },
  props: {},
  async mounted() {
    this.drawer = this.$vuetify.breakpoint.lgAndUp // disables navdrawer showing up on tablets and mobile devices
    let authResponse = await api.getFromEndpoint(api.ENDPOINTS.AUTH)
    if (authResponse) {
      this.$store.commit('updateUser', authResponse)
      const userName = `${authResponse.firstname} ${authResponse.lastname}`
      const userEmail = authResponse.email
      window.FreshworksWidget('identify', 'ticketForm', {
        name: userName,
        email: userEmail,
      });
      let accountSubscription = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.user.id + "/" + api.ENDPOINTS.ACCOUNTSUBSCRIPTION)
      if (accountSubscription) {
        authResponse.accountSubscription = accountSubscription
        if(accountSubscriptionTypes.TYPES[authResponse.accountSubscription.type] === accountSubscriptionTypes.TYPES.BASIC)
        {
          this.$store.commit('updateUser', null)
          location.href = (process.env.NODE_ENV === 'production' ?  constants.BASIC_PROD_URL : constants.BASIC_DEV_URL)
        }
        else {
          this.$store.commit('setAppIsLoading', false)
          this.$store.commit('updateUser', authResponse)
          if (this.$posthog && !this.$posthog._isIdentified()) {
            this.$posthog.identify(
                'user_' + authResponse.id,
                {
                  email: authResponse.email,
                  name: userName,
                  accountSubscription: authResponse.accountSubscription.type
                }
            );
          }
        }
      }
    }
    this.$nextTick(() => {
      // Headway widget for changelogs
      var HW_config = {
        selector: "#headwayChangelog", // CSS selector where to inject the badge
        account: "72BBZ7",
        translations: {
          title: "Nyheter & uppdateringar",
          readMore: "Läs vidare",
          footer: "Läs vidare"
        }
      }

      this.$store.dispatch('updateUnreadMessages', this.user)

      websocket.initiateWebSocketConnection()

      // Headway needs timeout to properly init
      window.setTimeout(() =>
          // eslint-disable-next-line
          Headway.init(HW_config), 1500)
    })
  },
  watch: {
    $route: {
      async handler(to) {
        // react to route changes...
        if (!this.$store.state.tours || this.$store.state.tours.length <= 0) {
          await this.getTours()
        }
        this.$store.commit('setCurrentRoute', to.name)

        let tour = this.$store.state.tours.find(tour => tour.routeName === this.currentRoute)
        if (tour && tour.id) {
          this.currentTour = tour
        } else {
          this.currentTour = null
        }
      },
      immediate: true
    },
  },
  methods: {
    async logoutUser() {
      if(this.$posthog)
      {
        this.$posthog.reset()
      }
      this.$store.commit('setAppIsLoading', true)
      this.$store.commit('updateUser', null)
      await api.deleteReqToEndpoint(api.ENDPOINTS.AUTH)
      location.href = "/"
    },
    snackbarActionClick() {
      this.snackbar.actionClick()
      this.closeSnackbar()
    },
    closeSnackbar() {
      this.$store.commit('setSnackbar', {active: false})
    },
    closeDialog() {
      this.$store.commit('setDialog', {active: false})
    },
    dialogActionClick() {
      this.dialog.actionClick()
      this.closeDialog()
    },
    async getTours() {
      let tourResponse = await api.getFromEndpoint(api.ENDPOINTS.TOURS)
      if (tourResponse) {
        this.$store.commit('setTours', tourResponse)
      }
    },
    startTour() {
      if (!this.editTour) {
        this.$tours['walkthroughTour'].start()
      }
    }
  }
}
</script>

<style>
.bottom-loader {
  position: absolute;
  bottom: 10px;
  margin: 0;
}

#HW_badge {
  height: 20px !important;
  width: 20px !important;
  line-height: 20px !important;
  font-size: 15px !important;
  top: 6px !important;
  left: 8px !important;
}

.v-card__title {
  padding: 16px 16px 16px !important;
}

.clickable {
  cursor: pointer;
}

.clickable-table tr {
  cursor: pointer;
}

.clickable textarea {
  cursor: pointer;
}

.clickable input {
  cursor: pointer;
}

.avatar td {
  padding: 0 !important;
  height: 36px !important;
  font-size: 17px !important;
}

.v-data-table-header-mobile__wrapper {
  min-width: 80px;
}

.force-on-top {
  z-index: 100 !important;
}

.remove-padding .v-speed-dial__list {
  padding: 0;
}

.dimmed-image img {
  filter: brightness(75%);
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.25) !important;
}

.scrollable {
  overflow-y: auto;
}

.avatar-outline {
  border: 1px solid #8380b6;
  border-radius: 50%;
}

.v-step__header {
  background-color: #897fa5 !important;
  color: white;
}

.v-step__content {
  font-size: 0.85rem;
}

.v-toolbar__title {
  text-overflow: unset;
}

.v-application p {
  margin-bottom: 6px;
}

.v-step__button {
  background: #897fa5 !important;
  border: .05rem solid #7f749c !important;
  color: #fff !important;
}

.v-step__button:hover {
  background: transparent !important;
  border: .05rem solid #7f749c !important;
  color: #897fa5 !important;
}

.outlined {
  border: 1px solid #dad8d8;
}

</style>
