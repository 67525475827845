<template>
  <div>
    <v-card flat :loading="loading" :disabled="loading">
      <v-subheader>Annonsinställningar</v-subheader>
      <BookingObjectListItemComponent
          @itemClick="goToListing"
          append-icon="mdi-open-in-new"
          :hide-description="true"
          :bookingObject="bookingObject"></BookingObjectListItemComponent>
      <v-card-text>
          <v-switch
              data-cy="bookingObjectPublishedSwitch"
              v-model="bookingObject.published"
              :loading="loading"
              label="Publicerad"
              persistent-hint
              hint="En opublicerad annons är inte sökbar och går inte att besöka."
              @change="emitUpdate"
          ></v-switch>
          <v-switch
              data-cy="bookingObjectHiddenFromSearchSwitch"
              :disabled="!bookingObject.published"
              v-model="bookingObject.hiddenFromSearch"
              :loading="loading"
              label="Dold från sökmotorer"
              persistent-hint
              hint="Om du aktiverar detta så kommer inte annonsen att visas i sökresultat på externa sökmotorer."
              @change="emitUpdate"
          ></v-switch>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import BookingObjectListItemComponent from "@/components/bookingobject/BookingObjectListItemComponent.vue";
import constants from "@/util/constants";

export default {
  components: {BookingObjectListItemComponent},
  props: {
    bookingObject: Object
  },
  data: () => ({
    loading: false,
  }),
  emits: ['update:bookingObject'],
  methods: {
    emitUpdate() {
      this.$emit('update:bookingObject', this.bookingObject);
    },
    goToListing() {
      this.loading = true
      const baseUrl = process.env.NODE_ENV === 'production' ? constants.BASIC_PROD_URL : constants.BASIC_DEV_URL
      location.href = `${baseUrl}/listings/item/${this.bookingObject.id}`
    }
  }
}
</script>
