<template>
  <v-navigation-drawer
      id="navDrawer"
      v-model="modelValue"
      :src="require('../../assets/mountains.jpg')"
      app
      class="navdrawer-background elevation-1"
      clipped
      height="100%"
  >
    <v-list dense>
      <v-list>
        <v-tooltip bottom nudge-top="25" open-delay="1550">
          <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                        <v-list-item id="avatar" two-line
                                                     @click="$router.push({name: 'adminUseraccount'})">
                                            <v-list-item-avatar>
                                              <initials-avatar-component
                                                  :imgSrc="userAvatar"
                                                  :size="32"
                                                  :firstname="user.firstname"
                                                  :lastname="user.lastname"
                                              ></initials-avatar-component>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{
                                                    user.firstname + ' ' + user.lastname
                                                  }}</v-list-item-title>
                                                <v-list-item-subtitle>{{
                                                    user.applicationEmailAddress
                                                  }}</v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="user.accountSubscription">
                                                  <v-btn block
                                                         class="py-3"
                                                         disabled
                                                         outlined
                                                         x-small>{{
                                                      subscriptionName
                                                    }}</v-btn>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </span>
          </template>
          <span>
                                    {{ user.applicationEmailAddress }}
                                </span>
        </v-tooltip>
        <v-list-item :href="uthyrdBasicUrl">
          <v-list-item-content>
            <v-list-item-subtitle>
              <v-btn
                  block
                  color="accent"
                  text
                  x-small>
                <v-icon left>mdi-open-in-new</v-icon>
                Uthyrd annonsportal
              </v-btn>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list-item-group color="primary">
        <v-list-item :data-cy="item.dataCy" v-for="item in navItems" :key="item.text" :to="item.to">
          <v-list-item-icon>
            <v-slide-x-transition mode="out-in">
              <v-avatar v-if="item.badge" :color="item.badge.color" height="23px"
                        min-width="22px"
                        width="23px"><span
                  class="white--text subtitle-2">{{ item.badge.text }}</span>
              </v-avatar>
              <v-icon v-else>{{ item.icon }}</v-icon>
            </v-slide-x-transition>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader>Mitt konto</v-subheader>
        <v-list-item data-cy="navCalendarSubscription" :to="{name: 'adminCalendarSubscription'}">
          <v-list-item-icon>
            <v-icon>mdi-calendar-repeat-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Kalenderprenumerationer</v-list-item-title>
        </v-list-item>
        <v-list-item data-cy="navAdminEmailContent" :to="{name: 'adminEmailContent'}">
          <v-list-item-icon>
            <v-icon>mdi-email-edit-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>E-postinnehåll</v-list-item-title>
        </v-list-item>
        <v-list-item data-cy="navAdminDynamicDocuments" :to="{name: 'dynamicDocuments'}">
          <v-list-item-icon>
            <v-icon>mdi-file-document-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Dynamiska dokument</v-list-item-title>
        </v-list-item>
        <v-list-item data-cy="navAdminUserAccount" :to="{name: 'adminUseraccount'}">
          <v-list-item-icon>
            <v-icon>mdi-settings-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Kontoinställningar
            <!-- TODO show alert-icon if subscription is close to ending --></v-list-item-title>
        </v-list-item>
        <div v-if="user.systemAdmin === 1">
          <v-subheader class="mt-3 grey--text text--darken-1">Systemadministration</v-subheader>
          <v-list-item :to="{name: 'adminAttributes'}" class="mt-1">
            <v-list-item-icon>
              <v-icon>mdi-label-percent-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Attribut</v-list-item-title>
          </v-list-item>
          <v-list-item :value="editTour" @click="$store.commit('setEditTour', !editTour)">
            <v-list-item-icon>
              <v-icon>mdi-teach</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Guidad genomgång</v-list-item-title>
          </v-list-item>
        </div>
        <v-divider class="mt-5"></v-divider>
        <v-list-item class="mt-2 mb-2" @click="$emit('logout')">
          <v-list-item-icon>
            <v-icon>power_settings_new</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logga ut</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import InitialsAvatarComponent from "@/components/global/InitialsAvatarComponent"
import api from "@/api/api";
import accountSubscriptionTypes from "@/models/accountSubscriptionTypes";
import constants from "@/util/constants";

export default {
  components: {InitialsAvatarComponent},
  props: {
    drawer: false,
  },
  computed: {
    modelValue: {
      get() {
        return this.drawer
      },
      set(value) {
        this.$emit('update:drawer', value)
      }
    },
    editTour() {
      return this.$store.state.editTour
    },
    navItems() {
      return [
        {
          icon: 'mdi-home-outline', text: 'Hem', to: {name: 'home'}, dataCy: 'navHome'
        },
        {
          icon: 'mdi-calendar-outline', text: 'Uthyrningsperioder', to: {name: 'bookingPeriods'}, dataCy: 'navPeriods'
        },
        this.messagesNavItem,
        {
          icon: 'mdi-account-card-details-outline', text: 'Kunder', to: {name: "customers"}, dataCy: 'navCustomers'
        },
        {
          icon: 'mdi-home-city-outline',
          text: 'Uthyrningsobjekt',
          to: {name: 'bookingObjects'},
          dataCy: 'navBookingObjects'
        },
      ]
    },
    messagesNavItem() {
      return {
        icon: this.unreadMessages > 0 ? null : 'mdi-message-text-outline',
        badge: this.unreadMessages > 0 ? {text: this.unreadMessages, color: 'error'} : null,
        text: 'Meddelanden',
        to: {name: "messages"},
        dataCy: 'navMessages'
      }
    },
    unreadMessages() {
      return this.$store.state.unreadMessages
    },
    subscriptionName() {
      if (this.user && this.user.accountSubscription) {
        return accountSubscriptionTypes.TYPES[this.user.accountSubscription.type].name
      }
      return null
    },
    user() {
      return this.$store.state.user
    },
    userAvatar() {
      if (this.$store.state.user.fileData && this.$store.state.user.fileData.src) {
        return api.BASE_URL + this.$store.state.user.fileData.src
      }
      return ""
    },
    uthyrdBasicUrl() {
      return (process.env.NODE_ENV === 'production' ? constants.BASIC_PROD_URL : constants.BASIC_DEV_URL)
    }
  },
}
</script>
<style>
.navdrawer-background {
  position: fixed !important;
}

nav.navdrawer-background.v-navigation-drawer.v-navigation-drawer--absolute.v-navigation-drawer--clipped.v-navigation-drawer--is-mobile.v-navigation-drawer--open.theme--light {
  z-index: 6;
}

.navdrawer-background .v-navigation-drawer__image {
  background-size: cover;
  background-position: 50%;
  opacity: 0.08;
  min-height: 1400px;
}
</style>
