<template>
    <v-fade-transition mode="out-in">
        <v-list v-if="bookingObjects.length > 0" :shaped="shaped" two-line>
            <v-subheader>Uthyrningsobjekt</v-subheader>
            <v-list-item-group :mandatory="mandatory" multiple v-model="bookingPeriodsDisplayOptions.value.filteredBookingObjects" color="primary">
                <booking-object-list-item-component
                        v-for="item in bookingObjects"
                        :key="item.title"
                        :booking-object="item"
                        @itemClick="itemClick(item)"
                        :value="item.id"
                >

                </booking-object-list-item-component>
            </v-list-item-group>
        </v-list>
        <no-data-component icon="settings-outline" v-else-if="!loading"
                           text="Du har inte skapat några uthyrningsobjekt än. Gör det i administrationsvyn"></no-data-component>
    </v-fade-transition>
</template>

<script>
    import api from "../../api/api";
    import NoDataComponent from "../../components/global/NoDataComponent";
    import BookingObjectListItemComponent from "./BookingObjectListItemComponent";

    export default {
        components: {BookingObjectListItemComponent, NoDataComponent},
        data: () => ({
            bookingObjects: [],
            loading: true
        }),
        props: {
            shaped: Boolean,
            selectFirst: Boolean,
            mandatory: Boolean,
            multiple: Boolean,
            bookingPeriodsDisplayOptions: Object
        },
        async mounted()
        {
            let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.$store.state.user.id + "/" + api.ENDPOINTS.BOOKINGOBJECTS)
            if (serverResponse)
            {
                this.bookingObjects = serverResponse
            }
            this.$emit("loaded")
            this.loading = false
        },
        methods: {
            itemClick(item)
            {
                this.$emit("itemClick", item)
            }
        },
    }
</script>

<style scoped>

</style>
