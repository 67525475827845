<template>
  <div>
    <v-container fluid>
      <v-item-group v-model="selectedPriceIndex" value="id">
        <v-row>
          <v-col
              v-for="item in prices"
              :key="item.id"
              cols="12"
              md="6"
          >
            <account-subscription-price-item-component :banner-text="item.bannerText"
                                                       :price="item"></account-subscription-price-item-component>
          </v-col>
        </v-row>
      </v-item-group>
      <div class="text-center mt-10">
        <h3 class="my-4">Antal uthyrningsobjekt</h3>
        <v-btn v-if="$vuetify.breakpoint.smAndUp" :disabled="minusSeatsDisabled" class="mr-1" color="primary"
               elevation="2" small @click="minusTenSeats">
          -10
        </v-btn>
        <v-btn :disabled="minusSeatsDisabled" class="mr-10" color="primary" elevation="2"
               @click="accountSubscription.seats--">
          <v-icon>mdi-minus</v-icon>
        </v-btn>
        <span class="headline">{{ accountSubscription.seats }}</span>
        <v-btn class="ml-10" color="primary" elevation="2" @click="accountSubscription.seats++">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn v-if="$vuetify.breakpoint.smAndUp" class="ml-1" color="primary" elevation="2" small
               @click="accountSubscription.seats += 10">
          +10
        </v-btn>
        <p class="mt-3">1 uthyrningsobjekt ingår i priset ovan. Därefter kostar varje extra
          uthyrningsobjekt 50kr per månad eller 480kr per år beroende på angivet betalningsintervall</p>
        <p class="grey--text caption"><b>OBS</b> Det går inte att välja färre uthyrningsobjekt än vad du har i ditt
          konto.
          För att sänka antalet uthyrningsobjekt i din prenumeration måste du först ta bort oönskade uthyrningsobjekt
          från
          ditt konto</p>
      </div>
    </v-container>
    <div v-if="hasSubscription">
      <v-list>
        <v-subheader>Nuvarande prenumeration</v-subheader>
        <v-list-item>
          <v-list-item-title>{{ getPriceFromId(dbSubscription.stripePriceId).name }}</v-list-item-title>
          <v-list-item-subtitle>{{ getPriceFromId(dbSubscription.stripePriceId).price }} /
            {{ getPriceFromId(dbSubscription.stripePriceId).paymentInterval.altText }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-title>{{ dbSubscription.seats }} Uthyrningsobjekt</v-list-item-title>
          <v-list-item-subtitle v-if="dbSubscription.seats > 1">{{ seatsCost }}kr / {{
              getPriceFromId(dbSubscription.stripePriceId).paymentInterval.altText
            }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="font-weight-bold">Totalt: {{ currentTotalPrice }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
    <v-slide-y-transition>
      <v-list v-if="selectedPrice" id="summary">
        <v-subheader>Ny prenumeration:</v-subheader>
        <v-list-item>
          <v-list-item-title>{{ selectedPrice.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ selectedPrice.price }} / {{
              selectedPrice.paymentInterval.altText
            }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-title>{{ accountSubscription.seats }} Uthyrningsobjekt</v-list-item-title>
          <v-list-item-subtitle v-if="accountSubscription.seats > 1">{{ seatsCost }}kr / {{
              selectedPrice.paymentInterval.altText
            }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="font-weight-bold">Totalt: {{ newTotalPrice }}</v-list-item-title>
        </v-list-item>
        <div v-if="loadingUpcoming" class="pa-5 text-center">
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
          <p class="mt-3">Beräknar ditt pris...</p>
        </div>
        <div v-else-if="hasSubscription && subscriptionChanged && upcomingPayment" class="pl-4 mt-2">
          <v-divider></v-divider>
          <v-subheader class="pl-0">Sammanfattning</v-subheader>
          <v-list-item-title>Ändringen av din prenumeration innebär följande:</v-list-item-title>
          <v-list-item-subtitle v-if="upcomingPayment.immediateTotal > 0">Du kommer att få betala
            <b>{{ upcomingPayment.immediateTotal }} SEK</b> idag
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else-if="upcomingPayment.creditAmount > 0">
            <v-menu
                close-delay="300"
                max-width="400px"
                offset-y
                open-delay="150"
                open-on-hover
                top
                transition="slide-y-reverse-transition"
            >
              <template v-slot:activator="{ on }">
                <span>Tillgodo: <b>{{ upcomingPayment.creditAmount }} SEK</b></span>
                <v-icon v-on="on" class="ml-1" color="grey" small style="vertical-align: text-top">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <v-card>
                <v-card-title>Vad är detta?</v-card-title>
                <v-card-text>
                  <p>När du har återstående betald tid så läggs det till i ditt saldo. Saldot räknas sedan av från nästkommande
                    betalningar.</p>
                  <p>Nästa betalning som står angivet nedan är alltså din kostnad minus det du får tillgodo
                    ({{ upcomingPayment.creditAmount }} SEK)</p>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-list-item-subtitle>
          <v-list-item-subtitle>Nästa betalning på <b>{{ upcomingPayment.nextInvoiceSum }} SEK</b> dras från ditt kort
            <b>{{ upcomingPayment.nextPaymentDate | calendarDate($moment) }}</b>
            <v-menu
                close-delay="300"
                max-width="400px"
                offset-y
                nudge-left="300"
                open-delay="150"
                open-on-hover
                top
                transition="slide-y-reverse-transition"
                v-if="upcomingPayment.nextInvoiceSum === 0"
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="ml-1" color="grey" small style="vertical-align: text-top">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <v-card>
                <v-card-title>Varför är denna 0?</v-card-title>
                <v-card-text>
                  <p>Du har mer tillgodo i ditt saldo än vad nästa faktura kommer att kosta dig. Därför kommer det inte att dras några pengar från ditt konto.</p>
                  <p>Detta förfarande kommer att upprepas tills dess att ditt saldo är slut.</p>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-list-item-subtitle>
        </div>
      </v-list>
    </v-slide-y-transition>
  </div>
</template>

<script>
import AccountSubscriptionPriceItemComponent
  from "@/components/useraccount/accountsubscription/AccountSubscriptionPriceItemComponent";
import api from "@/api/api";
import accountSubscriptionPrices from "@/models/accountSubscriptionPrices";

export default {
  components: {AccountSubscriptionPriceItemComponent},
  data: () => ({
    prices: accountSubscriptionPrices.PRICES,
    loading: false,
    loadingUpcoming: false,
    selectedPriceIndex: null,
    bookingObjectsCount: null,
    dbSubscription: null, // the AccountSubscription as it looks in the database, pre current changes
    upcomingPayment: null
  }),
  props: {
    subscription: Object,
    priceValid: Boolean,
    userId: Number,
  },
  filters: {
    calendarDate(val, moment)
    {
      return moment(val).calendar()
    }
  },
  computed: {
    accountSubscription: {
      get: function ()
      {
        return this.subscription;
      },
      set: function (newValue)
      {
        this.$emit('update:subscription', newValue)
      }
    },
    selectedPrice()
    {
      if (this.selectedPriceIndex != null)
      {
        return this.prices[this.selectedPriceIndex]
      }
      return null
    },
    seatsCost()
    {
      let seatsAdjusted = parseInt(this.accountSubscription.seats) - 1
      let calc = this.selectedPrice ? (seatsAdjusted * this.selectedPrice.seatPriceInt).toString(): 0
      return seatsAdjusted > 0 ? calc : 0
    },
    currentTotalPrice()
    {
      if (this.hasSubscription)
      {
        let currentPrice = this.getPriceFromId(this.dbSubscription.stripePriceId)
        let seatsAdjusted = parseInt(this.dbSubscription.seats) - 1
        return (seatsAdjusted * currentPrice.seatPriceInt + currentPrice.priceInt).toString() + "kr / " + currentPrice.paymentInterval.altText
      }
      return 0
    },
    newTotalPrice()
    {
      let seatsAdjusted = parseInt(this.accountSubscription.seats) - 1
      return (seatsAdjusted * this.selectedPrice.seatPriceInt + this.selectedPrice.priceInt).toString() + "kr / " + this.selectedPrice.paymentInterval.altText
    },
    minusSeatsDisabled()
    {
      return parseInt(this.accountSubscription.seats) <= this.minimumNumberOfSeats
    },
    minimumNumberOfSeats()
    {
      return this.bookingObjectsCount > 0 ? this.bookingObjectsCount : 1
    },
    valid()
    {
      return !(this.selectedPriceIndex == null || !this.accountSubscription.seats || this.accountSubscription.seats < this.minimumNumberOfSeats)
    },
    hasSubscription()
    {
      return this.dbSubscription && this.dbSubscription.stripePriceId
    },
    subscriptionChanged()
    {
      if (this.hasSubscription)
      {
        return this.dbSubscription.stripePriceId !== this.subscription.stripePriceId || this.dbSubscription.seats !== this.subscription.seats
      }
      return true
    },
  },
  watch: {
    selectedPrice(val)
    {
      this.$nextTick(() =>
      {
        // Scroll to the bottom element
        document.getElementById('summary').scrollIntoView({
          behavior: 'smooth'
        });
      })
      this.accountSubscription.stripePriceId = val.id
      this.accountSubscription.paymentInterval = val.paymentInterval.value
      this.getUpcomingPayment()
    },
    valid: {
      handler(val)
      {
        this.$emit('update:priceValid', val)
      },
      immediate: true
    },
    'accountSubscription.seats'()
    {
      this.getUpcomingPayment()
    }
  },
  async mounted()
  {
    this.dbSubscription = Object.assign({}, this.accountSubscription)
    await this.getBookingObjectsCount()
    this.setPriceIndex()
  },
  methods: {
    async getBookingObjectsCount()
    {
      this.loading = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.userId + "/" + api.ENDPOINTS.BOOKINGOBJECTS + "/" + api.ENDPOINTS.COUNT)
      if (serverResponse)
      {
        this.bookingObjectsCount = serverResponse
        if (this.accountSubscription.seats < serverResponse)
        {
          this.accountSubscription.seats = serverResponse
        }
      }
      this.loading = false
    },
    minusTenSeats()
    {
      if (this.accountSubscription.seats - 10 >= this.minimumNumberOfSeats)
      {
        this.accountSubscription.seats -= 10
      }
      else
      {
        this.accountSubscription.seats = this.minimumNumberOfSeats
      }
    },
    getPriceFromId(id)
    {
      return this.prices.find(price => price.id === id)
    },
    async getUpcomingPayment()
    {
      if (this.hasSubscription && this.subscriptionChanged)
      {
        this.loadingUpcoming = true
        let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.STRIPE + "/" + api.ENDPOINTS.UPCOMING
            + "?priceId=" + this.accountSubscription.stripePriceId
            + "&seats=" + this.accountSubscription.seats)
        if (serverResponse)
        {
          this.upcomingPayment = serverResponse
        }
        this.loadingUpcoming = false
      }
    },
    setPriceIndex()
    {
      if (this.hasSubscription)
      {
        this.selectedPriceIndex = this.prices.findIndex(price => price.id === this.dbSubscription.stripePriceId)
      }
    }
  }
}
</script>
