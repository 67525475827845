<template>
  <div>
    <listing-settings-component 
      :booking-object="bookingObject"
      @update:bookingObject="$emit('update:bookingObject', $event)"
    ></listing-settings-component>
    <visit-logs-component :booking-object-id="bookingObject.id"></visit-logs-component>
  </div>
</template>

<script>
import VisitLogsComponent from "@/components/bookingobject/tabs/listing/VisitLogsComponent.vue";
import ListingSettingsComponent from "@/components/bookingobject/tabs/listing/ListingSettingsComponent.vue";

export default {
  components: {ListingSettingsComponent, VisitLogsComponent},
  props: {
    bookingObject: Object
  },
  emits: ['update:bookingObject']
}
</script>
