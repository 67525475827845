<template>
  <v-container fill-height grid-list-xl>
    <v-layout row wrap>
      <v-flex :class="[infoMinimized ? 'xs12': 'md7 sm6', 'animatable']">
        <v-card :disabled="savingConversation" :loading="loadingMessages || savingConversation" color="white">
          <v-toolbar :color="this.conversation.archived === 1 ? 'grey darken-1':'secondary'" dark>
            <v-btn icon @click="$router.go(-1)">
              <v-icon>arrow_back</v-icon>
            </v-btn>
            <v-chip v-if="this.conversation.archived === 1" class="mr-2" color="primary lighten-3" label
                    outlined style="min-width: 110px;">
              <v-icon class="mr-2" small>mdi-alert</v-icon>
              Arkiverad
            </v-chip>
            <v-toolbar-title style="width: 100%">
              <v-hover v-slot:default="{ hover }">
                <v-text-field v-model="conversation.name" :readonly="savingConversation"
                              :solo-inverted="hover || toolbarFocused" color="accent"
                              dense
                              full-width
                              hide-details placeholder="Konversationsnamn"
                              single-line
                              @blur="toolbarFocused = false"
                              @focus="toolbarFocused = true" @keyup="saveConversation"></v-text-field>
              </v-hover>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon ripple @click="infoMinimized = !infoMinimized">
              <v-icon>{{ infoMinimized ? 'indeterminate_check_box' : 'aspect_ratio' }}</v-icon>
            </v-btn>
          </v-toolbar>
          <no-data-component v-if="conversation.messages.length <= 0" class="mb-10 mt-10"
                             icon="mdi-alert-circle-outline"
                             text="Hoppsan! Verkar som att det inte finns några meddelanden här"></no-data-component>
          <transition-group name="slide-y-transition">
            <message-card-component
                v-for="message in conversation.messages"
                :key="message.id"
                :message="message"
                @emailAdressClick="emailAdressClick"
                @showMessageDetails="showMessageDetails"
            ></message-card-component>
          </transition-group>
          <v-form
              ref="form"
              v-model="validForm"
              class="elevation-5"
              @submit.prevent>
            <v-card-text>
              <customer-email-select-component
                  ref="customerEmail"
                  v-model="newMessage"
                  :read-only="loading"
                  class="pl-2 pr-3 pb-3"
                  dense
                  hide-selected
                  label="Till"
                  prepend-inner-icon="mdi-email-outline"
                  required
                  single-line
                  validate
              ></customer-email-select-component>
              <v-spacer></v-spacer>
              <v-text-field
                  v-model="newMessage.subject"
                  :disabled="loading"
                  :rules="[v => !!v || 'Du måste ange ett ämne']"
                  autocomplete="off"
                  class="pl-2 pr-3 pt-1 mb-2"
                  flat
                  hide-details
                  label="Ämne"
                  required
                  @keydown.ctrl.enter.exact.once="sendMessage"
                  @keydown.meta.enter.exact.once="sendMessage"
              ></v-text-field>
              <v-textarea
                  id="replyInput"
                  v-model="newMessage.body"
                  :disabled="loading"
                  :rules="[v => !!v || 'Du måste skriva ett meddelande']"
                  autofocus
                  flat
                  no-resize
                  placeholder="Meddelande | Ctrl/Cmd + Enter för att skicka"
                  required
                  rows="5"
                  solo
                  @keydown.ctrl.enter="sendMessage"
                  @keydown.meta.enter="sendMessage"
              ></v-textarea>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pb-3 pt-3">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-badge
                      :content="newMessage.attachments.length"
                      :value="attachmentsAdded"
                      color="accent"
                      overlap
                  >
                    <v-menu v-on="on" offset-y top
                            transition="slide-y-reverse-transition">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on"
                               :disabled="conversation.messages.length <= 0 || loading" class="elevation-3 ml-3"
                               color="white" fab
                               small
                        >
                          <v-icon color="accent-darken darken-2">mdi-paperclip</v-icon>
                        </v-btn>
                      </template>
                      <v-list class="pa-0">
                        <v-list-item :disabled="loading" @click="fileUploadDialog = true">
                          <v-list-item-title>Ladda upp</v-list-item-title>
                          <v-list-item-action>
                            <v-icon color="primary">mdi-cloud-upload-outline</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item :disabled="!conversation.bookingObject || loading"
                                     @click="openFilePicker(false)">
                          <v-list-item-title>Välj från uthyrningsobjektet</v-list-item-title>
                          <v-list-item-action>
                            <v-icon color="accent">mdi-file-cabinet</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item :disabled="!conversation.bookingPeriod || loading"
                                     @click="openFilePicker(true)">
                          <v-list-item-title>Välj från uthyrningsperioden</v-list-item-title>
                          <v-list-item-action>
                            <v-icon color="info">mdi-calendar-text-outline</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item :disabled="!conversation.bookingPeriod || loading"
                                     @click="dynamicDocumentsDialog = true">
                          <v-list-item-title>Skapa fil från dynamiskt dokument</v-list-item-title>
                          <v-list-item-action>
                            <v-icon color="teal">mdi-file-document-outline</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item v-if="attachmentsAdded" :disabled="loading"
                                     @click="attachmentsDialog = true">
                          <v-list-item-title>Se bifogade filer</v-list-item-title>
                          <v-list-item-action>
                            <v-icon color="secondary">mdi-file-edit-outline</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-badge>
                </template>
                <span>Bifoga filer</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" :disabled="conversation.messages.length <= 0 || loading"
                         class="elevation-3 mr-3"
                         color="primary" fab
                         @click="sendMessage">
                    <v-icon>mdi-send</v-icon>
                  </v-btn>
                </template>
                <span>Skicka meddelande</span>
              </v-tooltip>
            </v-card-actions>
            <v-progress-linear :active="loading" absolute bottom class="bottom-loader"
                               indeterminate></v-progress-linear>
          </v-form>
        </v-card>
      </v-flex>
      <v-fade-transition>
        <v-flex v-if="!infoMinimized" class="md5 sm6">
          <v-card :disabled="conversation.messages.length <= 0 || savingConversation"
                  :loading="savingConversation"
                  width="100%">
            <v-card-title class="pb-0">
              <v-subheader class="pl-0">Kund</v-subheader>
            </v-card-title>
            <v-card-text>
              <customer-details-card-component v-if="conversation.customer"
                                               ref="customerDetails" :customer-object="conversation.customer"
                                               class="pt-0 pb-0 mb-8"
                                               flat hide-title
                                               read-only></customer-details-card-component>
              <customer-autocomplete-component
                  :key="'customers_'+conversation.id"
                  :read-only="savingConversation"
                  :value-object="conversation"
                  clearable
                  show-when-empty
                  shown
                  @change="saveConversation"
              ></customer-autocomplete-component>
              <v-btn v-if="conversation.customer" :to="{name: 'customers', params:{'id': conversation.customer.id}}"
                     class="mb-2"
                     color="primary" min-width="210px" outlined small>Gå till
                kunddetaljer
              </v-btn>
              <div>
                <customer-details-request-button-component v-if="conversation.customer"
                                                           :conversation-id="conversation.id"
                                                           :customer="conversation.customer"
                                                           button-color="accent"
                                                           icon-color="info darken-1"
                                                           icon-color-sent="success darken-1"
                                                           min-width="210px"
                                                           small
                                                           @requestSent="getConversation"></customer-details-request-button-component>
              </div>
              <v-subheader class="pl-0">Uthyrningsperiod</v-subheader>
              <booking-period-autocomplete-component
                  ref="periodselect"
                  v-model="conversation"
                  :read-only="savingConversation"
                  clearable
                  @change="bookingPeriodChanged"
              ></booking-period-autocomplete-component>
              <v-btn v-if="conversation.bookingPeriod"
                     :disabled="conversation.bookingPeriod.deleted"
                     :to="{name: 'bookingPeriodDetails', params: {id: conversation.bookingPeriod.id}}"
                     class="mb-2" color="primary" outlined small>Gå till period
              </v-btn>
              <v-subheader class="pl-0">Uthyrningsobjekt</v-subheader>
              <booking-object-select-component
                  v-model="conversation"
                  :read-only="savingConversation || conversation.bookingPeriod != null"
                  clearable
                  @change="saveConversation"></booking-object-select-component>
              <address-component v-if="conversation.bookingObject" :address="conversation.bookingObject.address"
                                 :opened-index="1"
                                 read-only></address-component>
              <v-btn v-if="conversation.bookingObject"
                     :to="{name: 'editBookingObject', params: {id: conversation.bookingObject.id}}" class="mb-2 mt-4"
                     color="primary" outlined small>Gå till uthyrningsobjekt
              </v-btn>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-fade-transition>
      <message-details-component v-if="selectedMessage" :dialog.sync="messageDetailsDialog"
                                 :loading="loadingMessageDetailsDialog"
                                 :message="selectedMessage" body-html></message-details-component>
      <v-dialog v-model="fileUploadDialog" max-width="800px">
        <v-card>
          <v-card-title>
            Ladda upp filer
            <v-spacer></v-spacer>
            <v-icon color="primary">mdi-cloud-upload-outline</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-3">
            <file-upload-component :max-filesize="10" :maxfiles="25"
                                   @fileUploaded="filesAttached"></file-upload-component>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="fileUploadDialog = false">Stäng</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="attachmentsDialog" max-width="800px">
        <v-card>
          <v-card-title>
            Bifogade filer
            <v-spacer></v-spacer>
            <v-icon color="primary">mdi-paperclip</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-3">
            <message-attachments-component :attachments="newMessage.attachments"
                                           show-actions></message-attachments-component>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="attachmentsDialog = false">Stäng</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <file-picker-dialog v-if="conversation.bookingObject || conversation.bookingPeriod"
                          v-model="newMessage.attachments"
                          :dialog.sync="filePickerDialog" :files="conversationFiles"
                          multiple></file-picker-dialog>
      <dynamic-documents-picker-dialog-component :dialog.sync="dynamicDocumentsDialog" @select="selectDynamicDocument"></dynamic-documents-picker-dialog-component>
      <dynamic-document-to-file-dialog-component eager @saved="dynamicDocumentSaved" :booking-period="conversation.bookingPeriod"
                                                 :dialog.sync="dynamicDocumentToFileDialog"
                                                 :selected-dynamic-document="selectedDynamicDocument"></dynamic-document-to-file-dialog-component>
    </v-layout>
  </v-container>
</template>

<script>
import api from "../../api/api";
import NoDataComponent from "../../components/global/NoDataComponent";
import CustomerEmailSelectComponent from "../../components/customer/CustomerEmailSelectComponent";
import BookingObjectSelectComponent from "../../components/bookingobject/BookingObjectSelectComponent";
import AddressComponent from "../../components/global/AddressComponent";
import MessageDetailsComponent from "../../components/conversation/message/MessageDetailsComponent";
import MessageCardComponent from "../../components/conversation/message/MessageCardComponent";
import FileUploadComponent from "../../components/global/file/FileUploadComponent";
import MessageAttachmentsComponent from "../../components/conversation/message/attachment/MessageAttachmentsComponent";
import FilePickerDialog from "../../components/global/file/FilePickerDialog";
import CustomerDetailsCardComponent from "../../components/customer/CustomerDetailsCardComponent";
import CustomerDetailsRequestButtonComponent from "../../components/customer/CustomerDetailsRequestButtonComponent";
import DynamicDocumentToFileDialogComponent from "@/components/dynamicdocument/DynamicDocumentToFileDialogComponent";
import BookingPeriodAutocompleteComponent from "@/components/bookingperiod/BookingPeriodAutocompleteComponent";
import CustomerAutocompleteComponent from "@/components/customer/CustomerAutocompleteComponent";
import DynamicDocumentsPickerDialogComponent from "@/components/dynamicdocument/DynamicDocumentsPickerDialogComponent";

export default {
  components: {
    DynamicDocumentsPickerDialogComponent,
    CustomerAutocompleteComponent,
    BookingPeriodAutocompleteComponent,
    DynamicDocumentToFileDialogComponent,
    CustomerDetailsRequestButtonComponent,
    CustomerDetailsCardComponent,
    FilePickerDialog,
    MessageAttachmentsComponent,
    FileUploadComponent,
    MessageCardComponent,
    MessageDetailsComponent,
    AddressComponent,
    BookingObjectSelectComponent,
    CustomerEmailSelectComponent,
    NoDataComponent
  },
  data: () => ({
    newMessage:
        {
          subject: "",
          body: "",
          email: "",
          attachments: []
        },
    toolbarFocused: false,
    messageDetailsDialog: false,
    loadingMessageDetailsDialog: false,
    fileUploadDialog: false,
    filePickerDialog: false,
    attachmentsDialog: false,
    dynamicDocumentsDialog: false,
    dynamicDocumentToFileDialog: false,
    selectedDynamicDocument: null,
    selectedMessage: null,
    validForm: false,
    debounceTimer: null,
    customers: [],
    loadingMessages: false,
    savingConversation: false,
    emailAddressSearch: "",
    loading: false,
    infoMinimized: false,
    filesFromPeriod: false,
    conversation: {
      name: "",
      messages: [],
      customer: null,
      bookingPeriod: null,
      bookingObject: null,
      userAccountId: null
    }
  }),
  computed: {
    customerImage()
    {
      if (this.conversation.customer && this.conversation.customer.fileData)
      {
        return api.BASE_URL + this.conversation.customer.fileData.src
      }
      return ""
    },
    attachmentsAdded()
    {
      return this.newMessage.attachments.length > 0
    },
    conversationFiles()
    {
      if (this.filesFromPeriod)
      {
        return this.conversation.bookingPeriod.files
      }
      else if (this.conversation.bookingObject)
      {
        return this.conversation.bookingObject.files
      }
      return []
    }
  },
  async mounted()
  {
    if (this.$route.params.id > 0)
    {
      this.loadingMessages = true
      await this.getConversation()
      this.loadingMessages = false
      this.setMessagesAsRead()
    }
  },
  methods: {
    async sendMessage()
    {
      this.$refs.form.validate()
      if (this.validForm && this.conversation.messages.length > 0 && this.validateEmail())
      {
        this.loading = true
        // Create the object from our form
        let sentEmailMessage = {
          conversation: this.conversation,
          fromName: this.$store.state.user.firstname + ' ' + this.$store.state.user.lastname,
          fromEmail: this.$store.state.user.applicationEmailAddress,
          toEmail: this.newMessage.email,
          subject: this.newMessage.subject,
          message: this.newMessage.body,
          attachments: this.newMessage.attachments
        }

        if (sentEmailMessage.toEmail.toUpperCase() === this.conversation.latestCreatedMessage.emailAddress.toUpperCase())
        {
          // If sending a message to the latestCreatedMessage we set the inReplyTo-field with messageId of latestCreatedMessage
          sentEmailMessage.inReplyTo = this.conversation.latestCreatedMessage.messageId
        }

        let serverResponse = await api.postToEndpoint(api.ENDPOINTS.CONVERSATIONS + "/" + api.ENDPOINTS.MESSAGE, sentEmailMessage)
        if (serverResponse)
        {
          // add the returned message to our list and show snackbar
          this.conversation.messages.push(serverResponse)
          this.$store.commit('setSnackbar', {
            color: "success",
            text: "Meddelande skapat och skickat till " + sentEmailMessage.toEmail
          })

          // un-archive if conversation is archived
          this.conversation.archived = 0

          // Reset and focus form
          document.getElementById('replyInput').scrollIntoView({
            behavior: 'smooth'
          })

          // clear message props
          this.newMessage.body = ""
          this.newMessage.attachments = []

          // finally reload conversation so latest message is shown
          this.getConversation()
        }
        this.loading = false
      }
    },
    async getConversation()
    {
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.CONVERSATIONS + "/" + this.$route.params.id)
      if (serverResponse)
      {
        Object.assign(this.conversation, serverResponse);
        let length = this.conversation.messages.length

        // Set subject and emailAddress to last message in the conversation
        this.newMessage.subject = this.conversation.messages[length - 1].subject
        this.newMessage.email = this.conversation.messages[length - 1].emailAddress

        if (this.conversation.bookingPeriod)
        {
          this.$refs.periodselect.setInitialPeriod(this.conversation.bookingPeriod)
        }
      }
      this.$nextTick(() =>
      {
        // Scroll to the bottom element
        document.getElementById('replyInput').scrollIntoView({
          behavior: 'smooth'
        });
      })
    },
    async setMessagesAsRead()
    {
      let serverResponse = await api.putToEndpoint(`${api.ENDPOINTS.CONVERSATIONS}/${this.$route.params.id}/${api.ENDPOINTS.MESSAGES}/read`)
      if (serverResponse)
      {
        Object.assign(this.conversation, serverResponse);
        this.$store.dispatch('updateUnreadMessages', this.$store.state.user)
      }
    },
    bookingPeriodChanged()
    { // set customer and bookingobject from the bookingperiod
      let bookingPeriod = this.conversation.bookingPeriod
      if (bookingPeriod)
      {
        if (!this.conversation.customer && bookingPeriod.customers) // only select the bookingPeriods customer if the conversation has no customer
        {
          this.conversation.customer = bookingPeriod.customers.find((obj) => obj.mainContact > 0)
          this.$nextTick(() =>
          {
            this.$refs.customerDetails.setInitCustomer()
          })
        }
        if (bookingPeriod.bookingObject)
        {
          this.conversation.bookingObject = bookingPeriod.bookingObject
        }
      }
      this.saveConversation()
    },
    saveConversation()
    {
      // Called when some property is changed on the conversation
      // Uses debounce to limit number of requests to backend
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(async () =>
      {
        if (this.conversation.messages.length > 0)
        {
          this.savingConversation = true
          await api.putToEndpoint(api.ENDPOINTS.CONVERSATIONS + "/" + this.conversation.id, this.conversation)
          this.$nextTick(() =>
          {
            if (this.conversation.customer)
            {
              this.$refs.customerDetails.setInitCustomer()
            }
          })
          this.savingConversation = false
        }
      }, 400);
    },
    emailAdressClick(emailAddress)
    {
      this.newMessage.email = emailAddress
      document.getElementById('replyInput').scrollIntoView({
        behavior: 'smooth'
      })
    },
    async showMessageDetails(message)
    {
      this.messageDetailsDialog = true
      this.loadingMessageDetailsDialog = true
      let serverResponse = await api.getFromEndpoint(`${api.ENDPOINTS.MESSAGES}/${api.ENDPOINTS.RECEIVED}/${message.id}`)
      if (serverResponse)
      {
        this.selectedMessage = serverResponse
      }
      this.loadingMessageDetailsDialog = false
    },
    filesAttached(response, file) // when image is uploaded we update the bookingObject
    {
      response.forEach((fileData) =>
      {
        let attachment = {name: file.name, fileData: fileData}
        this.newMessage.attachments.push(attachment)
      })
    },
    validateEmail()
    {
      this.$refs.customerEmail.validateInput()
      return this.newMessage.email && this.newMessage.email.indexOf("@") >= 0
    },
    openFilePicker(filesFromPeriod)
    {
      this.filePickerDialog = true
      this.filesFromPeriod = filesFromPeriod
    },
    selectDynamicDocument(dynamicDocument)
    {
      this.selectedDynamicDocument = Object.assign({}, dynamicDocument)
      this.dynamicDocumentToFileDialog = true
    },
    dynamicDocumentSaved(fileData)
    {
      this.dynamicDocumentToFileDialog = false
      this.conversation.bookingPeriod.files.push(fileData)
      this.$store.commit('setSnackbar', {
        color: "success",
        text: "Dynamiskt dokument sparad som fil i konversationens kopplade uthyrningsperiod"
      })
      this.dynamicDocumentsDialog = false
      this.openFilePicker(true)
    },
  }
}
</script>
<style scoped>
.animatable {
  transition: all 0.3s;
}


.v-text-field--solo {
  padding: 2px 0 8px !important;
}
</style>
