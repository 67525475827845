import posthog from 'posthog-js'

export default {
    install(Vue) {
        posthog.init(
            'phc_FC8FkxlwY0tdJS3JJhU853xAiNSbh3DcfPTQkBRZUhh',
            {
                api_host: 'https://eu.i.posthog.com',
                capture_pageview: false,
                session_recording: {
                    maskTextSelector: ".ph-sensitive" // masks all elements with the class "ph-sensitive". This does not apply to input elements.
                }
            }
        )
        Vue.prototype.$posthog = posthog
    }
}
