<template>
  <div>
    <v-card flat :loading="loading" :disabled="loading">
      <v-toolbar flat>
        <v-toolbar-title>Statistik</v-toolbar-title>
        <v-spacer></v-spacer>
        <span class="grey--text"></span>
      </v-toolbar>
      <v-divider class="mb-3"></v-divider>
      <v-slide-x-transition>
        <div v-if="loading" class="my-6 text-center">
          Laddar...
        </div>
        <div v-else-if="visitLogs.length > 0">
          <v-container fluid class="text-center">
            <v-row>
              <v-col
                  v-for="(item, index) in visitors"
                  :key="index + '_icon'"
                  cols="12"
                  sm="4"
              >
                <div class="mb-2">
                  <v-avatar color="white" class="elevation-3">
                    <h3 class="primary--text">{{ item.value }}</h3>
                  </v-avatar>
                </div>
                <p class="grey--text">{{ item.text }}</p>
              </v-col>
            </v-row>
          </v-container>
          <v-list dense>
            <v-subheader>Senaste {{ this.DEFAULT_LIMIT }} besöken</v-subheader>
            <visit-log-list-item-component
                :key="'log_' + log.id"
                :visit-log="log"
                v-for="log in truncatedVisitLogs">
            </visit-log-list-item-component>
          </v-list>
        </div>
        <no-data-component class="mt-10" icon="mdi-monitor-off" v-else
                           text="Det finns inga loggade besök att visa"></no-data-component>
      </v-slide-x-transition>
    </v-card>
  </div>
</template>

<script>

import api from "../../../../api/api";
import NoDataComponent from "@/components/global/NoDataComponent";
import deviceType from "@/models/deviceType";
import VisitLogListItemComponent from "@/components/visitlog/VisitLogListItemComponent.vue";

export default {
  components: {VisitLogListItemComponent, NoDataComponent},
  props: {
    bookingObjectId: Number
  },
  data: () => ({
    visitLogs: [],
    loading: false,
    DEFAULT_FROM_DAYS: 30,
    DEFAULT_LIMIT: 20,
  }),
  computed: {
    visitors() {
      return this.buildVisitors()
    },
    visitorsToday() {
      return this.visitLogs.filter(value => this.$moment(value.createdAt).isSame(this.$moment(), 'day'))
    },
    mobileVisitors() {
      return this.getFilteredVisitorLogs(deviceType.types.MOBILE)
    },
    tabletVisitors() {
      return this.getFilteredVisitorLogs(deviceType.types.TABLET)
    },
    truncatedVisitLogs()
    {
      return this.visitLogs.filter((value, index) => index <= this.DEFAULT_LIMIT)
    },
  },
  mounted() {
    this.getVisitLogs()
  },
  methods: {
    async getVisitLogs() {
      let fromDate = this.$moment().subtract(this.DEFAULT_FROM_DAYS, 'days').format("YYYY-MM-DD")
      this.loading = true

      let logs = await api.getFromEndpoint(
          api.ENDPOINTS.LISTINGS + "/" + api.ENDPOINTS.ITEM + "/"
          + this.bookingObjectId + "/" + api.ENDPOINTS.VISITS + "/"
          + api.ENDPOINTS.LOGS +
          "?fromDate=" + fromDate)

      if (logs) {
        this.visitLogs = logs
      }
      this.loading = false
    },
    buildVisitors() {
      const mobileVisitorsPercentage = (this.mobileVisitors.length / this.visitLogs.length) * 100;
      return [
        {
          text: "Besök idag",
          value: this.visitorsToday.length
        },
        {
          text: "Besök denna månad",
          value: this.visitLogs.length
        },
        {
          text: "Andel mobila enheter",
          value: Math.floor(mobileVisitorsPercentage) + "%"
        },
      ]
    },
    getFilteredVisitorLogs(deviceType) {
      const filtered = this.visitLogs.filter(value => value.deviceType === deviceType)
      return !!filtered ? filtered : []
    }
  }
}
</script>
