<template>
  <v-divider v-if="item.divider"></v-divider>
  <v-list-item
      v-else
      :key="item.name + item.id"
      class="mb-3"
      ripple
      :to="disableNavigation ? null : {name: 'conversationDetails', params: {id: item.id}}"
  >
    <v-list-item-icon style="display: block; height: 155px; margin-top: 5px; margin-bottom: 0; padding-top: 15px">
      <v-tooltip v-if="item.archived === 1" bottom>
        <template v-slot:activator="{ on }">
          <v-icon
              class="mb-2 ml-1"
              v-on="on"
              color="grey">
            mdi-archive
          </v-icon>
        </template>
        <span>Denna konversation är arkiverad</span>
      </v-tooltip>
      <v-tooltip v-else bottom>
        <template v-slot:activator="{ on }">
          <v-btn
              icon
              v-on="on"
              style="margin-left: -2px"
              class="mb-2"
              @click.prevent="confirmArchive(item)"
          >
            <v-icon color="error" dark>mdi-folder-remove-outline</v-icon>
          </v-btn>
        </template>
        <span>Arkivera konversation</span>
      </v-tooltip>
      <v-tooltip bottom v-if="item.customer">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <initials-avatar-component
                :imgSrc="getImageUrl(item.customer)"
                :size="32"
                :firstname="item.customer.firstname"
                :lastname="item.customer.lastname"
                class="mb-1"
            ></initials-avatar-component>
          </div>
        </template>
        <span>{{ `Kund - ${item.customer.firstname} ${item.customer.lastname}` }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="item.bookingObject">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <initials-avatar-component
                :imgSrc="getImageUrl(item.bookingObject)"
                :size="32"
                :fullname="item.bookingObject.name"
            ></initials-avatar-component>
          </div>
        </template>
        <span>{{ `Uthyrningsobjekt - ${item.bookingObject.name}` }}</span>
      </v-tooltip>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        {{ item.name }}
      </v-list-item-title>
      <v-list-item-subtitle><b>{{ item.latestCreatedMessage.author }}</b>
      </v-list-item-subtitle>
      <v-list-item-subtitle class="ph-sensitive">{{ item.latestCreatedMessage.body }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-list-item-action-text>
        {{ $moment(item.latestCreatedMessage.createdAt).fromNow() }}
      </v-list-item-action-text>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" class="mt-2"
                  :color="item.numberOfUnread > 0 ? 'error' : 'grey'">
            {{
              item.numberOfUnread > 0 ?
                  "mdi-message-alert-outline" : "mdi-message-outline"
            }}
          </v-icon>
        </template>
        <span>{{ `Det finns ${item.numberOfUnread} olästa meddelanden i denna konversation` }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="item.bookingPeriod">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" class="mt-2"
                  :color="bookingPeriodStatuses.STATUSES[item.bookingPeriod.status].color">
            {{ bookingPeriodStatuses.STATUSES[item.bookingPeriod.status].icon }}
          </v-icon>
        </template>
        <span>{{
            `${item.bookingPeriod.name} | ${bookingPeriodStatuses.STATUSES[item.bookingPeriod.status].text}`
          }}</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import bookingPeriodStatuses from "../../models/bookingPeriodStatuses";
import api from "../../api/api";
import InitialsAvatarComponent from "@/components/global/InitialsAvatarComponent";

export default {
  components: {InitialsAvatarComponent},
  data: () => ({
    bookingPeriodStatuses: bookingPeriodStatuses
  }),
  props: {
    item: Object,
    allowArchive: Boolean,
    disableNavigation: Boolean,
  },
  methods: {
    getImageUrl(item) {
      if (item.fileData && item.fileData.src) {
        return api.BASE_URL + item.fileData.src
      }
      return ""
    },
    confirmArchive(item) {
      this.$emit('confirmArchive', item)
    },
    async archiveConversation(conversation) {
      this.$emit('archiveConversation', conversation)
    },
  }
}
</script>
