<template>
    <v-select
            :items="availableStatuses"
            label="Status"
            :multiple="multiple"
            :chips="chips"
            prepend-icon="mdi-format-list-checks"
            v-model="statusValue"
            @change="itemClick"
            :disabled="disabled"
    >
        <template v-slot:selection="data">
            <v-chip
                    v-bind="data.attrs"
                    outlined
                    v-if="chips"
            >
                <v-avatar left>
                    <v-icon :color="data.item.color">{{data.item.icon}}</v-icon>
                </v-avatar>
                {{ data.item.text }}
            </v-chip>
            <v-list-item class="pa-0" v-else>
                <v-avatar left>
                    <v-icon :color="data.item.color">{{data.item.icon}}</v-icon>
                </v-avatar>
                {{ data.item.text }}
            </v-list-item>
        </template>
        <template v-slot:item="data">
            <v-list-item-avatar>
                <v-icon :color="data.item.color">{{data.item.icon}}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>{{data.item.text}}</v-list-item-title>
            </v-list-item-content>
        </template>
    </v-select>
</template>

<script>
    import bookingPeriodStatuses from "../../models/bookingPeriodStatuses";

    export default {
        data: () => ({
            availableStatuses: bookingPeriodStatuses.STATUSES
        }),
        props: {
            disabled: Boolean,
            multiple: Boolean,
            chips: Boolean,
            value: Object // value.statuses must be an array if multiple, otherwise value.status should be an int
        },
        computed: {
            statusValue: {
                get()
                {
                    return this.multiple ? this.value.statuses : this.value.status
                },
                set(val)
                {
                    if (this.multiple)
                    {
                        this.value.statuses = val
                    }
                    else
                    {
                        this.value.status = val
                    }
                }
            }
        },
        methods: {
            itemClick(item)
            {
                this.$emit("itemClick", item)
            }
        }
    }
</script>

<style scoped>
    .override-disabled {
        pointer-events: all;
        cursor: help;
    }
</style>
