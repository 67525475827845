<template>
  <div>
    <v-container fill-height fluid>
      <v-layout align-center justify-center>
        <v-flex lg6 md10 xs12>
          <transition appear mode="out-in" name="slide-x-transition">
            <v-alert class="elevation-6 mt-16 mb-6" color="primary" prominent type="error">
              <template v-slot:prepend>
                <v-btn class="mr-6 ml-2 highlight-pulse" icon>
                  <v-icon x-large>mdi-alert-circle</v-icon>
                </v-btn>
              </template>
              <h3>{{ title }}</h3>
              <p>{{ text }}</p>
              <p class="caption mt-6">Behöver du hjälp? Kontakta oss på <a style="color: white;" href="mailto:support@uthyrd.se">support@uthyrd.se</a> så hjälper vi dig ✌️</p>
              <v-divider class="mt-4" dark></v-divider>
              <v-card-actions>
                <v-btn dark plain @click="$emit('logout')">Logga ut</v-btn>
                <v-spacer></v-spacer>
                <account-subscription-manage-button-component color="white" dark :account-subscription="accountSubscription" @openSubscriptionDialog="subscriptionDialog = true"></account-subscription-manage-button-component>
              </v-card-actions>
            </v-alert>
          </transition>
        </v-flex>
      </v-layout>
    </v-container>
    <account-subscription-dialog-component :dialog.sync="subscriptionDialog"
                                           :subscription.sync="accountSubscription"></account-subscription-dialog-component>
  </div>
</template>

<script>

import accountSubscriptionTypes from "@/models/accountSubscriptionTypes";
import AccountSubscriptionDialogComponent
  from "@/components/useraccount/accountsubscription/AccountSubscriptionDialogComponent";
import AccountSubscriptionManageButtonComponent
  from "@/components/useraccount/accountsubscription/AccountSubscriptionManageButtonComponent";

export default {
  components: {AccountSubscriptionManageButtonComponent, AccountSubscriptionDialogComponent},
  data: () => ({
    subscriptionDialog: false
  }),
  props: {
    accountSubscription: Object,
  },
  computed: {
    isTester()
    {
      return accountSubscriptionTypes.TYPES[this.accountSubscription.type] === accountSubscriptionTypes.TYPES.PREMIUM_TEST
    },
    title()
    {
      return this.isTester ? "Nu är din prövotid slut 😔" : "Din prenumeration har gått ut! 😔"
    },
    text()
    {
      return this.isTester ? "För att fortsätta använda Uthyrd måste du uppgradera din prenumeration. " +
          "Det kostar endast 129kr/månad (årsvis betalning). Klicka på knappen nedan till höger för att uppgradera ditt konto 🏅" :
          "Verkar som att du inte har förnyat din prenumeration i tid. Detta innebär att du inte längre kommer få notiser, meddelanden eller andra upplysningar från oss på Uthyrd och för att komma in på ditt konto igen måste du först förnya din prenumeration. Klicka på knappen nedan till höger för att se över din faktureringshistorik, kortuppgifter och förnya din prenumeration"
    },
  },
}
</script>

<style scoped>

</style>
