<template>
  <div>
    <v-card v-if="seatsLeft <= 0 && !objectId" class="elevation-10 px-3 pb-3">
      <v-card-title style="word-break: break-word;" class="primary--text">
        <v-icon class="mr-2" color="error">mdi-alert</v-icon>
        Du har nått maxgränsen för uthyrningsobjekt på din nuvarande prenumeration
      </v-card-title>
      <v-card-text style="word-break: break-word;">
        <p>Klicka på knappen nedan till höger för att ändra din prenumeration till en med fler uthyrningsobjekt för att
          lägga in fler i ditt konto</p>
      </v-card-text>
      <v-card-actions>
        <v-btn class="ml-0" outlined color="primary" :to="{name: 'adminUseraccount'}">Ändra prenumeration</v-btn>
      </v-card-actions>
    </v-card>
    <v-card
        v-else
        :flat="flat"
        :outlined="flat"
    >
      <v-card-title class="secondary">
        <v-icon
            dark
            left
        >
          mdi-home-city-outline
        </v-icon>
        <span class="title textfont-weight-light white--text">{{ title }}</span>
      </v-card-title>
      <v-tabs
          v-model="tab"
          background-color="transparent"
          color="accent"
          grow
          show-arrows
      >
        <v-tab data-cy="boOverviewTab">
          Översikt
        </v-tab>
        <v-tab data-cy="boFilesTab" v-if="!setup">
          Filer & Dokument
        </v-tab>
        <v-tab data-cy="boPaymentTab" v-if="!setup">
          Betalning
        </v-tab>
        <v-tab data-cy="boListingTab" v-if="!setup && bookingObject.id">
          Annons
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-card-text class="pt-0">
        <v-tabs-items v-model="tab" class="mt-4">
          <v-form
              ref="form"
              v-model="validForm"
              @submit.prevent
          >
            <v-tab-item>
              <booking-object-overview-tab-component
                  :address-content.sync="addressContent"
                  :booking-object.sync="bookingObject"
                  :loading="loading" @saveBookingObject="saveBookingObject"
                  :unsaved-changes.sync="unsaved"
              ></booking-object-overview-tab-component>
            </v-tab-item>
            <v-tab-item>
              <booking-object-files-tab-component :booking-object.sync="bookingObject"
                                                  @saveBookingObject="saveBookingObject"></booking-object-files-tab-component>
            </v-tab-item>
            <v-tab-item>
              <booking-object-payment-tab-component :unsaved-changes.sync="unsaved"
                                                    :booking-object.sync="bookingObject"
                                                    @saveBookingObject="saveBookingObject"></booking-object-payment-tab-component>
            </v-tab-item>
            <v-tab-item>
              <booking-object-listing-admin-component
                  :booking-object="bookingObject"
                  @update:bookingObject="saveBookingObject"
              ></booking-object-listing-admin-component>
            </v-tab-item>
          </v-form>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-btn data-cy="cancelBtn" :disabled="loading" color="default"
               @click="cancel">
          Avbryt
        </v-btn>
        <v-btn data-cy="deleteBtn" v-if="this.objectId > 0" :disabled="loading" color="error"
               @click="confirmDelete">
          Ta bort
          <v-icon right>delete</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn data-cy="saveBtn" :loading="loading" color="primary"
               @click="saveBookingObject()">Spara
          <v-icon right>save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import api from "../../api/api";
import filetypes from "../../models/filetypes";
import BookingObjectOverviewTabComponent from "./tabs/BookingObjectOverviewTabComponent";
import BookingObjectPaymentTabComponent from "./tabs/BookingObjectPaymentPointsTabComponent";
import {validateForm} from "../../util/functions";
import BookingObjectFilesTabComponent from "./tabs/BookingObjectFilesTabComponent";
import BookingObjectListingAdminComponent
  from "@/components/bookingobject/tabs/listing/BookingObjectListingAdminComponent.vue";

export default {
  components: {
    BookingObjectListingAdminComponent,
    BookingObjectFilesTabComponent,
    BookingObjectPaymentTabComponent,
    BookingObjectOverviewTabComponent,
  },
  data: () => ({
    validForm: true,
    loading: false,
    pickingAvatar: false,
    fileUploadDialog: false,
    tab: 0,
    bookingObject: {
      id: 0,
      name: "",
      active: 1,
      description: "",
      userAccountId: 0,
      fileData: null,
      attributes: [],
      defaultCheckInTime: "16:00",
      defaultCheckOutTime: "10:00",
      defaultPricePerPeriod: "0",
      paymentPoints: [],
      paymentInfo: "",
      files: [],
      address: {
        id: 0,
        address1: "",
        address2: "",
        address3: "",
        postcode: "",
        city: "",
        country: "Sverige",
      },
    },
    addressContent: 1
  }),
  props: {
    editId: {
      default: null
    },
    setup: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    seatsLeft: {
      type: Number,
      default: 1
    },
    unsavedChanges: Boolean
  },
  computed: {
    title()
    {
      return this.editingExisting ? `Redigerar ${this.bookingObject.name}` : "Nytt uthyrningsobjekt"
    },
    editingExisting()
    {
      return this.objectId > 0
    },
    objectId()
    {
      if (this.editId)
      {
        return this.editId
      }
      else
      {
        return this.$route.params.id
      }
    },
    bookingObjectAvatar()
    {
      if (this.bookingObject.fileData && this.bookingObject.fileData.src)
      {
        return api.BASE_URL + this.bookingObject.fileData.src
      }
      return ""
    },
    stateUser()
    {
      return this.$store.state.user
    },
    bookingObjectImages() // filtered array with only images
    {
      return this.bookingObject.files.filter(file => file.fileData.filetype === filetypes.TYPES.IMAGE)
    },
    unsaved: {
      get()
      {
        return this.unsavedChanges
      },
      set(val)
      {
        this.$emit('update:unsavedChanges', val)
      }
    },
  },
  async mounted()
  {
    this.bookingObject.userAccountId = this.stateUser.id
    if (this.objectId > 0)
    {
      await this.getBookingObject()
    }
    else
    {
      this.addressContent = 0
    }
    if (!this.bookingObject.paymentPoints || this.bookingObject.paymentPoints.length === 0)
    {
      this.bookingObject.paymentPoints = []
    }
    let tabIndex = this.$route.query.tabIndex
    if (tabIndex)
    {
      window.setTimeout(() =>
      {
        this.tab = Number.parseInt(tabIndex)
      }, 50)
    }
  },
  watch: {
    tab()
    {
      this.setTabIndexInRoute()
    }
  },
  methods: {
    async getBookingObject()
    {
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.BOOKINGOBJECTS + "/" + this.objectId)
      if (serverResponse)
      {
        Object.assign(this.bookingObject, serverResponse);
      }
    },
    setTabIndexInRoute()
    {
      if (!this.setup && this.objectId > 0)
      {
        window.history.replaceState(null, null, '?tabIndex=' + this.tab);
      }
    },
    cancel()
    {
      if (!this.setup)
      {
        this.$router.replace({name: "bookingObjects"})
      }
      else
      {
        this.$emit("goBack")
      }
    },
    confirmDelete()
    {
      this.$store.commit('setDialog', {
        active: true,
        closeBtnText: "Avbryt",
        actionBtnText: "Ta bort",
        title: "Bekräfta borttagning",
        text: `Är du säker på att du vill ta bort ${this.bookingObject.name} ?`,
        actionClick: this.deleteBookingObject
      })
    },
    async deleteBookingObject()
    {
      this.loading = true
      await api.deleteReqToEndpoint(api.ENDPOINTS.BOOKINGOBJECTS + "/" + this.bookingObject.id)
      this.loading = false
      this.$store.commit('setSnackbar', {color: "primary", text: `${this.bookingObject.name} borttagen`})
      if (!this.setup)
      {
        this.$router.replace({name: "bookingObjects"})
      }
      else
      {
        this.$emit("goBack")
      }
    },
    async saveBookingObject()
    {
      let form = this.$refs.form
      this.unsaved = false
      let response = null

      if (this.isPaymentPointsAndPriceValid() && validateForm(form))
      {
        let saveText = `${this.bookingObject.name} skapad`
        this.loading = true
        if (this.bookingObject.id > 0) // updating existing
        {
          saveText = `${this.bookingObject.name} uppdaterad`
          response = await this.updateReq()
        }
        else // creating a new
        {
          if (this.bookingObject.files && this.bookingObject.files.length > 0)
          {
            // if we are creating a new BookingObject and there are files uploaded we need to save the object first
            // and then set the new bookingObjectId and lastly update booking object with the files
            let files = Array.from(this.bookingObject.files)
            this.bookingObject.files = []
            response = await this.createReq()

            files.forEach((file) =>
            {
              file.bookingObjectId = this.bookingObject.id
            })

            this.bookingObject.files = Array.from(files)
            response = await this.updateReq()
          }
          else
          {
            response = await this.createReq()
          }
          if (!this.setup)
          {
            this.unsaved = false
            this.$nextTick(() => {this.$router.replace({name: "bookingObjects"})})
          }
          else
          {
            this.unsaved = false
            this.$nextTick(() => {this.$emit("goBack")})
          }
        }
        this.loading = false
        if (response)
        {
          this.$store.commit('setSnackbar', {color: "success", text: saveText})
        }
        else
        {
          this.$store.commit('setSnackbar', {color: "error", text: "Något gick fel! Kunde inte spara. Vänligen försök igen. Om problemet kvarstår kontakta support@uthyrd.se"})
        }
      }
    },
    async updateReq() {
      let response = await api.putToEndpoint(api.ENDPOINTS.BOOKINGOBJECTS + "/" + this.bookingObject.id, this.bookingObject)
      if(response)
      {
        this.bookingObject = response
        this.unsaved = false
        return response
      }
    },
    async createReq() {
      let response = await api.postToEndpoint(api.ENDPOINTS.BOOKINGOBJECT, this.bookingObject)
      if(response)
      {
        this.bookingObject = response
        this.unsaved = false
        return response
      }
    },
    selectImageAsAvatar(selectedImage)
    {
      this.bookingObject.fileData = selectedImage.fileData
      if (this.bookingObject.id > 0)
      {
        this.saveBookingObject()
      }
    },
    filesAttached(response, file) // when a file is uploaded we update the object
    {
      response.forEach((fileData) =>
      {
        let bookingObjectFile = {
          name: file.name,
          fileData: fileData,
          bookingObjectId: this.bookingObject.id
        }
        this.bookingObject.files.push(bookingObjectFile)
      })
    },
    filesUpdated()
    {
      if (this.bookingObject.id > 0)
      {
        this.saveBookingObject()
      }
    },
    fileRemoved()
    {
      if (this.bookingObject.id > 0)
      {
        this.saveBookingObject()
      }
    },
    isPaymentPointsAndPriceValid()
    {
      // check if price is specified and if paymentpoints are added, make sure they're valid
      let valid = parseInt(this.bookingObject.defaultPricePerPeriod) >= 0
      if (valid && this.bookingObject.paymentPoints && this.bookingObject.paymentPoints.length > 0)
      {
        let total = 0
        this.bookingObject.paymentPoints.forEach((p) =>
        {
          total += parseInt(p.percentage)
        })
        valid = total === 100
      }
      if (!valid)
      {
        this.tab = 2 // go to payments-tab if not valid
      }
      return valid
    },
  },
}
</script>

<style scoped>
</style>
